// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:72a18b29-3fb0-440d-a767-757e0cea0326",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zNkCpBbGN",
    "aws_user_pools_web_client_id": "36j58n0sk0ueoc82pqm2ajafao",
    "aws_appsync_graphqlEndpoint": "https://4djo2z424vaj7c52tzban2qomi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "photo-albumsf37e4cf3ee924b51a2cf757bb4dfc413-master",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "photo-albums-20190301175023-hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d22v96i175bkp7.cloudfront.net"
};


export default awsmobile;
